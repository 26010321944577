@font-face {
  font-family: 'Roboto Mono';
  src: url('./robotoMono/RobotoMono-Regular.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova Light';
  src: url('./proximaNova/ProximaNova-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('./proximaNova/ProximaNova-Regular.ttf') format('truetype');
}
